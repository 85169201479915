.royalSlider {
  width: 100%;
  // height: 400px;
  position: relative;
  direction: ltr;
}
.slider-images{
  // background-color:  @slider-grey;
}
.rsDefault.rsHor {
  .rsArrowLeft {
    .rsArrowIcn {
      background-image:url('/img/sliders/rsSlider_arrowLeft.png');
      height: 60px;
      background-color: transparent;
      background-position: top left;
      margin-left: 0;
      left: 50px;
    }
  }
}
.rsDefault.rsHor {
  .rsArrowRight {
    .rsArrowIcn {
      background-image:url('/img/sliders/rsSlider_arrowRight.png');
      height: 60px;
      background-color: transparent;
      background-position: top left;
      // margin-right: 30%;
      right: 50px;
      left: inherit;
    }
  }
}
.rsDefault .rsPlayBtnIcon {
  border-radius: 50%;
  width: 99px;
  height: 99px;
  background: url(../img/core/play.png) no-repeat 6px 14px;
  background-color: rgba(220,221,222,.95);
  &:hover {
     background-color: rgba(220,221,222,.95);
  }
}

.rsDefault .rsOverflow {
  background-color: transparent;
}
.royalSlider .rsSlide img {
    opacity: 0.45 !important;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.royalSlider .rsActiveSlide img {
    opacity: 1 !important;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
#floorplans {
  .rsSlide img {
    opacity: 0.45 ;
  }
  .rsActiveSlide img {
    opacity: 1;
  }
}

/***************
*
*  2. Bullets
*
****************/
.content_slider .rsBullets {
  position: relative;
  z-index: 35;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  margin: 0 auto;
  // background: #000;
  background: transparent;
  text-align: center;
  line-height: 8px;
  overflow: hidden;
}
.content_slider .rsBullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  padding: 6px 11px 6px;
}
.content_slider .rsBullet span {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
}
.content_slider .rsBullet.rsNavSelected span {
  // background-color: @core-white;
}
.pink .content_slider .rsBullet.rsNavSelected span {
  background-color: #fff;
}

.content_slider {
  position: relative;
}
.content_slider.rsHor {
  .rsArrowLeft {
    .rsArrowIcn {
    background-image:url('/img/sliders/rsSlider_arrowLeft.png');
    height: 60px;
    background-color: transparent;
    background-position: top left;
    width: 32px;
    left: 0;
    top: 35%;
    position: absolute;
    }
  }
}
.content_slider.rsHor {
  .rsArrowRight {
    .rsArrowIcn {
      background-image:url('/img/sliders/rsSlider_arrowRight.png');
      width: 32px;
      height: 60px;
      background-color: transparent;
      background-position: top left;
      right: 0;
      top: 35%;
      position: absolute;
    }
  }
}

.pink {
  .content_slider.rsHor {
    .rsArrowLeft {
      .rsArrowIcn {
        background-image:url('/img/sliders/rsSlider_arrowLeft.png');
      }
    }
    .rsArrowRight {
      .rsArrowIcn {
        background-image:url('/img/sliders/rsSlider_arrowRight.png');
      }
    }
  }
}
.rsDefault, .rsSlide {
  background-color: @core-color__default;
}