@import (less) "variables.less";

// http://tympanus.net/Development/HoverEffectIdeas/index.html
.team-image figure {
  position: relative;
  // float: left;
  float: none;
  overflow: hidden;
  margin: 10px 0%;
  // min-width: 320px;
  // max-width: 480px;
  // max-height: 360px;
  // width: 48%;
  // background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.team-image figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.team-image figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.team-image figure figcaption::before,
.team-image figure figcaption::after {
  pointer-events: none;
}

.team-image figure figcaption,
.team-image figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.team-image figure figcaption > a {
  z-index: 2;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.team-image figure h2 {
  // word-spacing: -0.15em;
  font-weight: 300;
}

.team-image figure h2 span {
  font-weight: 800;
}

.team-image figure h2,
.team-image figure p {
  margin: 0;
}

.team-image figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
  // background: #9e5406;
}

figure.effect-bubba img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-bubba:hover img, figure.effect-bubba:focus img {
  opacity: 0.4;
}
figure.effect-bubba:hover figcaption, figure.effect-bubba:focus figcaption {
  opacity: 0.9;
  background-color: @core-color__blue - 35%;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  transform: scale(1,0);
}

figure.effect-bubba h2 {
  padding-top: 30%;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,-20px,0);
  transform: translate3d(0,-20px,0);
}

figure.effect-bubba p {
  padding: 20px 2.5em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after,
figure.effect-bubba:focus figcaption::before,
figure.effect-bubba:focus figcaption::after, {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p,
figure.effect-bubba:focus h2,
figure.effect-bubba:focus p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-bubba:hover h2, figure.effect-bubba:focus h2 {
  color: #fff;
  font-size: 0.9em;
  font-weight: bold;
}

@media only screen and (max-width : 765px) {
    .team-image {
        height: inherit !important;

        figure img {
            min-height: inherit;
        }
    }

    figure.effect-bubba h2 {
        padding-top: 25%;
    }
}
