@import (less) "variables.less";
@import (less) "normalize.css";
@import (less) "menu-horizontal.less";
@import (less) "../bower_components/bootstrap/dist/css/bootstrap.css";
@import (less) "../node_modules/fancybox/dist/css/jquery.fancybox.css";
@import (less) "slider.less";
@import (less) "hover-effect.less";
// @import (less) "dropkick.less";
@import (less) "form.less";
@import (less) "hamburgers.css";
@import (less) "../js/vendor/magnific-popup/dist/magnific-popup.css";

/* CORE */
html, body {
	margin: 0;
	padding: 0;
  color: @core-color__default;
}
a, img {
    border:none;
    outline:none
}
h1,h2,h3,h4,h5,h6 {
  color: @core-color__gold;
}
.col-centered{
	// need important for ie8
    float: none !important;

    margin: 0 auto;
}

.svgLogo {
  height: 100%;
}
* {
  &:focus {
    outline: 0 none;
  }
}
iframe[name='google_conversion_frame'] {
    float: left;

    width: 0 !important;
    height: 0 !important;
    margin-top: -13px;

    font-size: 0 !important;
    line-height: 0 !important;
}

.rotate-dialog {
    display: none;
}

.fancybox-skin {
  background: @core-color__blue;
}

.downscroller {
  position: absolute;
  bottom: @core-margin;

  width: 22px;
  img {
    width: 100%;
    height: auto;
  }
}

.margin-top {
  margin-top: 30px;
}

.banner-default {
    height: 30vw;

    margin-top: 50px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image: url('../img/core/default-banner.jpg');
}

a[href^="x-apple-data-detectors:"] {
   color: #fff;
        text-decoration: none;
}
 a[href^="tel"], a[href^="sms"] {
   text-decoration: none;
   color: #fff;
}

.projects-locked {
  height: 70vh;
  overflow:hidden;
}

.home-intro {
  // position: relative;
  // height: 70vh;
  height: 100vh;
  padding-top: 50px;

  margin-bottom: @core-margin;

  background-color: @core-color__blue;
  background-image: url('../img/core/background-pattern.jpg');
  background-position: top left;
  background-size: cover;

  font-family: @font-family-base;
  h1, h2 {
    color: @core-color__gold;

    font-weight: 300;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      letter-spacing: 1px;

      color: @core-color__gold;
    }
  }
  h1 {
    font-size: 4em;
  }
  .regal-motif {
    position: absolute;
    top: 0;

    width:60px;
    height: auto;
  }

  .home-building-wealth {
    position: absolute;
    bottom: 0;

    margin-bottom: 0;

    letter-spacing: 1px;
    text-transform: uppercase;

    font-weight: 300;
  }
}
.home-menu-pusher {
  padding-left: 9px;
}

.banner-row {
  // margin-top: 100px;
}

.regal-home {
  width: 40%;
}
.home-small-logo {
  // margin-top: 15px;
}

.page-heading {
  margin-top: 0;

  text-transform: uppercase;

  font-size: 1.3em;

  .gold-col;
}
.gold-bar {
  .gold-bg;
}
.bar-branded {
  width: 33px;
  height: auto;
  margin: (@core-margin / 2 ) 0;
}
.read-more-link {
  text-decoration: none;
  text-transform: uppercase;

  font-size: 0.9em;

  .gold-col;
}

.copy-holder {
  letter-spacing: 1px;

  font-family: @font-family-sans-serif;
  font-weight: 300;
  .page-heading {
    font-weight: bold;
  }
  h4 {
    font-size: 24px;
    font-weight: 300;
  }
}


.video-wrapper{
  img {
    width: 100%;
  }
}
.main-image-holder {
  img {
    width: 100%;
  }
}

.footer {
  margin-top: @core-margin;
  // height: 500px;
  padding-top: @core-margin;

  background-color: @core-color__blue;
  .page-heading {
    color: #fff;
  }
  p {
    color: #fff;
  }
  &:after {
    display: block;

    width: 40%;
    height: 70px;

    content: " ";

    background-color: @core-color__blue;
  }
}

.contributor-logo {
  float: left;

  width: 25%;
  margin: @core-margin auto;

  text-align: center;
  img {
    width: 60%;
  }
}

.team-member {
  margin-bottom:  @core-margin;
}
.ind-team-photo {
  img {
    width: 100%;
  }
}

.small-logo {
  width: 33%;
  padding: 7px 0;

  @media (max-width: 668px) and (min-width:450px){
    padding-top: 11px !important;
  }

  @media(min-width:768px){
    padding: 10px 0;
  }

  @media(min-width:992px){
    padding: 6px 0;
  }

  @media(min-width:1025px){
    padding: 10px 0;
  }

  @media(min-width:1200px){
    padding: 7px 0;
  }
}



.banner-sabino-di-filippo,
.banner-scott-stekelenburg,
.banner-robert-mathieson,
.banner-ramona-morel-sinclair,
.banner-michael-oconnor,
.banner-xahni-langham, {
  .banner-default;
}


// These will eventually need to be images... might as well already create classes
.banner-team {
    .banner-default;

    background-image: url('../img/core/banner-team.jpg');
}

.banner-wealth-creation {
  .banner-default;

  background-image: url('../img/core/banner-wealth-creation.jpg');
}
.banner-property-investment {
  .banner-default;

  background-image: url('../img/core/banner-property-investment.jpg');
}

.banner-buyers-advocacy {
  .banner-default;

  background-image: url('../img/core/banner-buyer-advocacy.jpg');
}

.banner-car-financing {
  .banner-default;

  background-image: url('../img/core/banner-car-financing.jpg');
}

.banner-mortgage-and-finance{
  .banner-default;

  background-image: url('../img/core/banner-mortgage-and-finance.jpg');
}
.banner-projects {
  .banner-default;
}
.banner-calculator {
  .banner-default;

  background-image: url('../img/core/banner-calculator.jpg');
}
.banner-testimonials{
  .banner-default;

  background-image: url('../img/core/banner-testimonials.jpg');
}

.banner-contact {
    .banner-default;
    background-image: url('../img/core/banner-contact.jpg');
}

.banner-thankyou {
    .banner-contact;
}

// Projects banners
.banner-barker-maude {
    .banner-default;

    background-image: url('../img/projects/barker_maude.jpg');
}
.banner-jersey-carnegie {
    .banner-default;

    background-image: url('../img/projects/jersey-carnegie.jpg');
}
.banner-littlewood-hampton {
    .banner-default;

    background-image: url('../img/projects/littlewood-hampton.jpg');
}
.banner-ormond-road-elwood {
    .banner-default;

    background-image: url('../img/projects/ormond-elwood.jpg');
}
.banner-marque-maribyrnong {
    .banner-default;

    background-image: url('../img/projects/marque-maribyrnong.jpg');
}
.banner-supply-co-richmond {
    .banner-default;

    background-image: url('../img/projects/supply-co.jpg');
}
.banner-onyx-bentleigh-east {
    .banner-default;

    background-image: url('../img/projects/onyx-bentleigh-east.jpg');
}
.banner-house-and-land-savannah-estate-officer {
    .banner-default;

    background-image: url('../img/projects/savannah-house-land.jpg');
}
.banner-house-and-land-berwick-clyde-and-narre-warren {
    .banner-default;

    background-image: url('../img/projects/house-land-clyde.jpg');
}
.banner-house-and-land-lakeland-pakenham {
    .banner-default;

    background-image: url('../img/projects/house-land-lakeland.jpg');
}
.banner-brightwood-monash {
    .banner-default;

    background-image: url('../img/projects/brightwood-monash.jpg');
}
.banner-303-la-trobe {
    .banner-default;

    background-image: url('../img/projects/303-la-trobe.jpg');
}
.banner-greenwich-fairfield {
    .banner-default;

    background-image: url('../img/projects/greenwich-fairfield.jpg');
}
.banner-hunter-prahran {
    .banner-default;

    background-image: url('../img/projects/hunter-prahran.jpg');
}
.banner-rosslyn-apartments-west-melbourne {
    .banner-default;

    background-image: url('../img/projects/rosslyn-apartments.jpg');
}
.banner-embassy-richmond {
    .banner-default;

    background-image: url('../img/projects/embassy-richmond.jpg');
}
.banner-kings-domain-apartments {
    .banner-default;

    background-image: url('../img/projects/kings-domain.jpg');
}
.banner-brighton-edge {
    .banner-default;

    background-image: url('../img/projects/brighton-edge-hero.jpg');
}

.banner-the-grove {
    .banner-default;

    background-image: url('../img/projects/the-grove.jpg');
}
.banner-cv-tower {
    .banner-default;

    background-image: url('../img/projects/cv-tower.jpg');
}
.banner-no-1-porter-street {
    .banner-default;

    background-image: url('../img/projects/porter-st.jpg');
}
.banner-the-international {
    .banner-default;

    background-image: url('../img/projects/the-international.jpg');
}
.banner-hedgeley-malvern-east {
    .banner-default;

    background-image: url('../img/projects/the-international.jpg');
}
.banner-dart-street-highett {
    .banner-default;

    background-image: url('../img/projects/dart_st.jpg');
}
.banner-ivanhoe-apartments {
    .banner-default;

    background-image: url('../img/projects/ivanhoe_apartments.jpg');
}
.banner-the-modern {
    .banner-default;

    background-image: url('../img/projects/the_modern.jpg');
}
.banner-the-terraces {
    .banner-default;

    background-image: url('../img/projects/the_terraces.jpg');
}
.banner-vickery-st {
    .banner-default;

    background-image: url('../img/projects/vickery_st.jpg');
}
.banner-hedgeley-malvern-east {
    .banner-default;

    background-image: url('../img/projects/hedgeley-malvern.jpg');
}

.ul-less-margin {
  padding-left: 17px;
  li {
    padding: 3px 0;
  }
}

.main-copy-holder {
  p {
    // needs to match h4 on other col
    padding-top: 10px;
  }
}





.calculator-section-wrapper {
  margin-bottom: @core-margin;
}
.testimonial-wrapper {
  margin-bottom: (@core-margin * 2);
}
.testimonials-heading {
  padding-right: 15%;
}

// overrider footer margin top
// instances where there is a HR as the last thing above the footer
.contact, .wealth-creation {
  .footer {
    margin-top: 0;
  }
}
body.contact {
  background-color: @core-color__blue;
}


.social-icon-holder {
  float: left;

  width: 22px;
  margin-right: 10px;
  img {
    width: 100%
  }
}

.calculator {
  .main-copy-holder {
    a {
      color: #000;
    }
  }
}

 .team-image {
  height: 320px;
  margin-bottom: @core-margin;
}

.home {
  .menu-color-wrapper {
    margin-top: 0;
  }
}

.projects-login {
    position: absolute;
    z-index: 2;

    margin-top: -100px;
    // padding: 40px;

    text-align: center;
    // opacity: 0.9;

    background-color: rgba(0, 0, 21, 0.75);

    h2 {
        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: #fff;

        font-size: 1.2em;
        // font-weight: 700;
    }

    button {
        width: 100%;
        margin-bottom: 15px;

        text-transform: uppercase;

        color: @core-color__blue;
        border-style: none;

        font-size: .9em;

        padding: 7px 0;
        // font-weight: bold;

        .gold-bg;
    }

    a {
        color: #FFFFFF;

        font-size: 0.7em;

        &:hover {
            text-decoration: none;
        }
    }
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/img/fancybox/fancybox_sprite.png");
}

#fancybox-loading div {
    background: url("/img/fancybox/fancybox_loading.gif") center center no-repeat;
}

.fancybox-nav {
    background: transparent url("/img/fancybox/blank.gif");
}

.fancybox-overlay {
    background: url("/img/fancybox/fancybox_overlay.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
        background-image: url("/img/fancybox/fancybox_sprite@2x.png");
    }
    #fancybox-loading div {
        background-image: url("/img/fancybox/fancybox_loading@2x.gif");
    }
}


/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-device-width : 1024px) {
  .home {
   .menu-holder{
      margin-right: 15px;
    }
  }
  .contributor-logo  {
    img {
      width: 60%;
    }
  }
  .small-logo {
    width: 45%;
  }
}


/* Phones (tablets, 668 and up) */
@media (max-width: 668px) {
  .video-wrapper {
    .col-xs-12 {
      margin: 10px 0;
    }
  }
  // .no-mobile-padding {
  //   padding: 0;
  // }
  .small-logo {
    width: 100%;
    padding-top: 16px;
  }
  .contributor-logo {
    float: left;
    clear: both;

    width: 100%;
  }
  .caption-holder {
    margin-top:  @core-margin;
  }
  .menu-text {
    padding-top: 15px;
    padding-left: 5px;
  }
  .home-rs-holder {
    padding-left: 30px;
  }
  .home-motif {
    margin-top: 15px;
    padding-left: 15px;
  }
  .banner-home {
  .home-building-wealth {
    position: absolute;
    top: 0;
    right: 20px;

    margin-bottom: 0;

    text-transform: uppercase;
  }
  }

}

@media only screen and (max-device-width: 765px) {
    .banner-home h1 {
        font-size: 2em;
    }
    .banner-row {
        padding-top: 30px;
    }
    .home-motif {
        margin-top: auto;
    }
    .home-building-wealth {
        padding-left: 15px;
    }

    .home-ls-holder {
        position: absolute;
        bottom: 100px;
        width:100%;
    }
    .home-intro {
        h1 {
            font-size: 45px;
        }
    }
}


/* ipad Landscape only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .team-image {
    height: 250px;
    margin-bottom: @core-margin * 1.5;
  }
}

/* ipad Portrait only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
   .team-image {
    height: 182px;
    margin-bottom: @core-margin * 1.5;
  }
  .projects {
    .team-image {
      height: 300px;
      margin-bottom: @core-margin * 1.4;
    }
  }
}

//iphone landscape
@media only screen and (min-device-width : 320px) and (max-device-width : 765px) and (min-device-aspect-ratio: 13/9) and (orientation : landscape) {
    .page-wrapper {
        display: none;
    }

    .rotate-dialog {
       position: absolute;
       top: 50%;
       left: 50%;

       display: block;

       width: 100px;
       margin-right: -50%;

       transform: translate(-50%, -50%);

       .st0 {
           width: 100%;

           fill: #000;
       }
    }
}

/* Phones */
@media only screen and (min-device-width : 320px) and (max-device-width : 400px) and (orientation : portrait) {
    .home-intro {
        h1 {
            font-size: 2em;
        }
    }
}
