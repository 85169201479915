.menu-color-wrapper {
    position: fixed;
    z-index: 3;

    width:100%;
	height: @menu-height;
    margin-top: -50px;

	background-color: @core-color__blue;
}
.menu-holder {
  position: absolute;
  z-index: 1;

  overflow: hidden;

 	height: @menu-height;
  padding-left: @menu-padding;

  transition: 1s all;
}
.bar-menu-open {
	overflow: auto;

	height: auto;
}
.menu-text, .menu-burger {
	width: 45%;
}
.menu-text {
	float: left;

	padding-top: 15px;

	color: #fff;
}
.menu-burger {
	float: right;
	.hamburger {
		float: right;

		margin-top: 6px;
		padding: 10px;
		.hamburger-box {
			height: 15px;
		}
		.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
			width: 25px;
			height: 2px;

			background-color: #fff;
		}
		.hamburger-inner:before {
			top: -7px;

			height: 2px;
		}
		.hamburger-inner:after {
			top: -14px;
			bottom: -7px;
			// top: 0;

			height: 2px;
		}
	}
}
.menu-horizontal {
	margin: @menu-padding 0;
	padding: 0;
	// text-align: center;
	padding: @menu-padding 0 (@menu-padding * 2) 0;

	list-style: none;
	li {
		display: inline-block;

		width: 100%;
		margin-right: @menu-padding;
		padding: 0;
		padding: 4px 0;

		list-style: none;

		text-transform: uppercase;

		font-size: 0.9em;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			text-transform: uppercase;

			color: #000;
			&:hover {
				transition: 0.5s ease;
		 		text-decoration: underline;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}
