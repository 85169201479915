@labelCol: #FFF;

@inputCol: #FFF;

@backgroundCol: transparent;

@formFontSize: 0.9em;

@submitCol: #000000;
@submitBg: @core-color__gold;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
.form-holder {
  width: 350px;
  float: left;
}
.reg-form {
  padding-top: 2px;
}


.error {
  color: red !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: 35px;
  width: 100%;
  border: 0;
  font-size: @formFontSize;
  font-family: @font-family-base;
  font-weight: 300;
  letter-spacing: 0.05em;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
  border: 1px solid @inputCol;
  margin-bottom: 10px;
  padding-left: 5px;
}

.custom-textarea {
  height: 100px;
}

.custom-select {
  padding-top: 4px;
  height: 38px;
  box-sizing: border-box;
  background: url("../img/core/arrow.png") 97% 50% no-repeat @backgroundCol;
}

.custom-submit {
  color:@submitCol;
  background-color: @submitBg;
  border-color: @submitBg;
  box-shadow: none;
  margin-top: 0px;
  font-size: @formFontSize;
  text-align: center;
  transition: 0.5s ease;
  text-transform: uppercase;
  &:hover {
     // background-color: #28baaf;
  }
}


.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
.text-center {
  text-align: center;
  color: #ffffff;
  font-size: 1.8em;
  font-weight: 300;
  letter-spacing: 0.07em;
}
label {
  color: @labelCol;
  font-weight: bold;
}
::placeholder {
  color: #767676;
}

.form-field-left-half, .form-field-right-half, .form-field-full {
  float: left;
  width: 50%;
  height: 50px;
}
.form-field-right-half {
  float: right;

}
.form-field-full {
  width: 100%;
}
.form-field{
  clear: both;
}

.checkbox-input {
  float: left;
}
.checkbox-label {
  float: left;
  margin-left: (@core-margin / 3);
  text-transform: uppercase;
  width: 80%;
  font-weight: bold;
}

/* ipad Landscape only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {  }

/* ipad Portrait only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {  }

//iphone landscape
@media only screen and (min-device-width : 320px) and (max-device-width : 668px) and (orientation : landscape) {  }

/* Phones */
@media only screen and (min-device-width : 320px) and (max-device-width : 668px) and (orientation : portrait) {  }