// FONTS
@font-family-sans-serif:  "Roboto","Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

// COLOURS
@core-color__default:     #000000;
@core-color__blue:        #081938;
@core-color__gold:        #a07b2c;

@core-dropkick-bg:        #d9d9d2;
@core-dropkick-col:       #000000;

@core-margin:             30px;
@menu-height:             50px;
@menu-padding:            10px;



.gold-col {
  color: @core-color__gold;
}
.gold-bg {
  background-color: @core-color__gold;
}
.black-col {
  color: #000
}

.margin-top {
  margin-top: @core-margin;
}
.margin-bottom {
  margin-bottom: @core-margin;
}

.gold-hr {
  border-color: @core-color__gold;
}

.black-hr {
  border-color: #000000;
}

.white-hr {
  border-color: #ffffff;
}
